import Home from "./Home/Home";

const routes = [
    {
        path: '/',
        name: 'home',
        title: 'Home',
        component: Home
    }
]

export default routes;
